import React from 'react'
import { connect } from 'react-redux';
import { account } from '../../redux/actions/index';

import { Layout as LayoutAnt} from 'antd'
import Logo from './logo'
import { bindActionCreators } from 'redux';


const Link = ({url="#",children, ...rest}) => (
    <a className="btn-header" style={{color: "#fff"}} href={url} {...rest}>{children}</a>
)

const Layout = (Wrapped) => {
    class LayoutWrapper extends React.Component {
        render = () => (
        <LayoutAnt>
            <LayoutAnt.Header className="header">
                <Logo/>
                <Link url="/">Dashboard</Link>
                <Link url="/account">Account</Link>
                <Link onClick={this.props.signOut}>Sign out</Link>
            </LayoutAnt.Header>
            <div className="main-container">
                <Wrapped />
            </div>
            {/* <div className="footer">
                Copyright 2018 - LiveSession
            </div> */}
        </LayoutAnt>
        )
    }

const mapDispatchToProps = (dispatch) => (
    bindActionCreators({
        signOut: () =>  dispatch(account.signOutUser)
    }, dispatch)
)

return connect(null, mapDispatchToProps)(LayoutWrapper);
}

export default Layout;