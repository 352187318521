import React from 'react';
import { connect } from 'react-redux';
import {bindActionCreators} from 'redux';
import { Card, Col, Row, Tabs, Icon, Tooltip } from 'antd';
import { affiliate } from '../redux/actions/index';
import {checkStatus} from '../utils/accountUtils';
import '../style/dashboard.scss';

import Layout from '../components/layout/layout';

import Share from '../components/share';
import DataCard from '../components/card';
import Chart from '../components/chart';

const TooltipMRR = () => (
    <Tooltip title="Monthly Recurring Revenue">
        <Icon style={{marginLeft:3}} theme="filled" type="info-circle" />
    </Tooltip>
)


class DashboardView extends React.Component {

    componentDidMount() {
        const {accountStatus, getStats, fetchVisitsChart} = this.props;
        
        if(checkStatus(accountStatus))
        {   
            getStats(accountStatus.affiID);
            fetchVisitsChart(accountStatus.affiID);
        }        
    }

    handleChartTabChange = (key) => {
        const { visitsChart, 
                trialsChart, 
                leadsChart, 
                accountStatus, 
                fetchVisitsChart,
                fetchTrialsChart,
                fetchLeadsChart
            } = this.props;

        switch(key){
            case "visits":
                if(visitsChart===null && checkStatus(accountStatus)){
                    fetchVisitsChart(accountStatus.affiID);
                }
            break;
            case "trials":
                if(trialsChart===null && checkStatus(accountStatus)){
                    fetchTrialsChart(accountStatus.affiID);
                }
            break;
            case "leads":
                if(leadsChart===null && checkStatus(accountStatus)){
                    fetchLeadsChart(accountStatus.affiID);
                }
            break;
            default:
            break;
        }
    }

    render(){
        const {stats, visitsChart, trialsChart, leadsChart} = this.props;

        return (<>
                    <Row gutter="30">
                        <Col span="6" md="12">
                            <DataCard title="Visits" content={stats.visits} />
                        </Col>
                        <Col span="6" md="12">
                            <DataCard title="Accounts on trial" content={stats.trials} />
                        </Col>
                        <Col span="6" md="12">
                            <DataCard title="Active accounts" content={stats.leads} />
                        </Col>
                        <Col span="6" md="12">
                            <DataCard title={<span>MRR<TooltipMRR/></span>} content={stats.mrr} dollar/>
                        </Col>
                    </Row>
                    <Row gutter={30} style={{marginTop: "2em"}}>
                    <Col span="12">
                        <Card style={{padding: 10, height: 400}} bordered={false}  title={<span className="share-title">Share your affiliate link</span>}>
                            <div style={{marginTop: 70}}>
                            <Share link={`https://livesession.io?a=${this.props.accountStatus.affiID}`}/>
                            </div>
                        </Card>
                    </Col>
                    <Col span="12">
                        <Card style={{padding: 10, height: 400}} bordered={false} >
                            <Tabs onChange={this.handleChartTabChange}>
                                <Tabs.TabPane key="visits" tab="Visits over time">
                                        <Chart label="Visits" data={visitsChart}/>
                                </Tabs.TabPane>
                                <Tabs.TabPane key="trials" tab="Trials over time">                   
                                        <Chart label="Trials" data={trialsChart}/>                                
                                </Tabs.TabPane>
                                <Tabs.TabPane key="leads" tab="Leads over time">         
                                        <Chart label="Paid accounts" data={leadsChart} />                                                                
                                </Tabs.TabPane>
                            </Tabs> 
                        </Card>
                    </Col>
                    </Row>
                </>
        );
    }
}


const mapStateToProps = state => ({
    accountStatus: state.accountStatus,
    stats: state.stats,
    visitsChart: state.visitsChartData,
    trialsChart: state.trialsChartData,
    leadsChart: state.leadsChartData
    }
);

const mapDispatchToProps = dispatch => (
    bindActionCreators({
        getStats: affiliate.fetchStats,
        fetchVisitsChart: affiliate.fetchVisitsChart,
        fetchTrialsChart: affiliate.fetchTrialsChart,
        fetchLeadsChart: affiliate.fetchLeadsChart
    }, dispatch)
)
  
const Dash = connect(mapStateToProps, mapDispatchToProps)(DashboardView);

export default Layout(Dash);