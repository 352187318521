import React, {Component} from 'react';
import styled from 'styled-components';
import {Form, Input, Button} from 'antd';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import {FacebookShareButton,  TwitterShareButton, LinkedinShareButton} from 'react-share'

import fbIcon from '../img/social-share-facebook.svg';
import twIcon from '../img/social-share-twitter.svg';
import liIcon from '../img/social-share-linkedin.svg';

const ShareContainer = styled.div`

`

class Share extends Component {
    state = {
        coppied: false
    };

    handleCopy = () => {
        this.setState({coppied: true})
    }

    render = () => (<>
                    <ShareContainer>
                        <div className="share-copy">
                            <Form layout="inline">
                                <Form.Item>
                                    <Input style={{width: 250}} value={this.props.link}/>
                                </Form.Item>
                                <Form.Item>
                                    <CopyToClipboard text={this.props.link} onCopy={this.handleCopy}>
                                        <Button>{this.state.coppied ? "Coppied to clipboard" : "Copy to clipboard"}</Button>
                                    </CopyToClipboard>
                                </Form.Item>
                            </Form>
                        </div>

                        <div className="share-buttons">
                            <FacebookShareButton className="share-button" url={this.props.link}>
                                <img src={fbIcon} alt="Share on Facebook"/>
                            </FacebookShareButton>
                            <TwitterShareButton className="share-button" url={this.props.link}>
                                <img src={twIcon} alt="Share on Twitter"/>
                            </TwitterShareButton>
                            <LinkedinShareButton className="share-button" url={this.props.link}>
                                <img src={liIcon} alt="Share on LinkedIN"/>                    
                            </LinkedinShareButton>
                        </div>
                    </ShareContainer>
                    </>
    );
}



export default Share;