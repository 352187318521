import React from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {Alert, message, Input, Button, Form } from 'antd';
import { affiliate } from '../redux/actions/index';
import { checkStatus } from '../utils/accountUtils';

class UpdateAffiliateView extends React.Component {
    state = {
        name: '',
        contact_name: '',
        website: '', 
        requestCalled: false
    };

    componentDidMount() {
        const {name, contact_name, website}  = this.props.affiliateInfo;
        
        this.setState({
            name: name || '',
            contact_name: contact_name || '',
            website: website || ''
        })
    }

    handleChange = (e) => {
        switch(e.target.id){
            case "name":
                this.setState({name:e.target.value});
                break;
            case "contact_name":
                this.setState({contact_name:e.target.value});
                break;
            case "website":
                this.setState({website:e.target.value});
                break;
            default:
            break;
        }
    }

    validateSubmit = () => {
        const {name, contact_name} = this.state;

        if(!name || !contact_name){
            return false;
        } 

        if(typeof name !== 'string' ||  typeof contact_name !== 'string'){
            return false;
        }

        if(name.length === 0 || contact_name.length === 0){
            return false;
        }

        return true;
    };

    handleSubmit = (e) => {
        e.preventDefault();
        const {name,contact_name,website} = this.state;
        const { accountStatus } = this.props;
        
        if(this.validateSubmit() && checkStatus(accountStatus)){
            this.props.updateAffiliate(accountStatus.affiID, {
                name: name,
                contact_name: contact_name,
                website: website
            });
            this.setState({requestCalled:true})
        }else{
            message.warning("Please specify all obligatory fields.");
        }
    }

    componentDidUpdate() {
        const {updateStatus} = this.props;

        if(this.state.requestCalled && updateStatus && updateStatus.status && updateStatus.status === 'success'){
            this.props.onSuccess();
        }
    }

    render = () => {
        const {updateStatus, onGoBack} = this.props;
        const {contact_name, name, website} = this.state

        const isLoading = updateStatus && updateStatus.status && updateStatus.status === 'loading';
        const error = updateStatus && updateStatus.status && updateStatus.status === 'error' ;
        const errorMessage = error && updateStatus.message ? updateStatus.message : '';  
        
        return (
        <Form layout="vertical">
            <Form.Item label="Business name" required>
                <Input disabled={isLoading} id="name" value={name} onChange={this.handleChange}/>
            </Form.Item>
            <Form.Item label="Your full name" required>
                <Input disabled={isLoading} id="contact_name" value={contact_name} onChange={this.handleChange}/>
            </Form.Item>
            <Form.Item label="Website">
                <Input disabled={isLoading} id="website" value={website} onChange={this.handleChange} />
            </Form.Item>
            <Button loading={isLoading} onClick={this.handleSubmit} type="primary">Update profile</Button>
            <Button style={{marginLeft: 10}} onClick={onGoBack}>Go back</Button>
            {error && <Alert style={{marginTop: 20}} message={`Can't update affiliate profile. ${errorMessage}`} type="error"/>}
        </Form>
        )
    }
}

const mapStateToProps = state => ({
        accountStatus: state.accountStatus,
        updateStatus: state.affiliateUpdateStatus,
        affiliateInfo: state.affiliateInfo
});

const mapDispatchToProps = dispatch => (
    bindActionCreators({
        updateAffiliate: affiliate.updateAffiliate
    }, dispatch)
);

const UpdateAffiliate = connect(mapStateToProps, mapDispatchToProps)(UpdateAffiliateView);

export default UpdateAffiliate;