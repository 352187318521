import React, { Component } from 'react';
import store from './redux/store';
import { Provider } from 'react-redux';
import { BrowserRouter as Router, Route } from 'react-router-dom';

import withAuthentication from './components/mixins/withAuthentication';
import WithAuthorization from './components/mixins/withAuthorization';

import SignIn from './containers/signin'
import SignUp from './containers/register'
import Account from './containers/account'
import Dashboard from './containers/dashboard';


const ProtectedDashboard = withAuthentication(WithAuthorization(Dashboard));
const ProtectedAccount = withAuthentication(WithAuthorization(Account));

class App extends Component {
  render() {
    return (
    <Provider store={store}>
          <Router>
            <>
              <Route exact path="/signin" component={SignIn} />
              <Route exact path="/signup" component={SignUp} />
              <Route exact path="/" component={ProtectedDashboard} />
              <Route exact path="/account" component={ProtectedAccount} />

            </>
          </Router>
      </Provider>
    );
  }
}

export default App;
