import React, {
    Component
  } from 'react';
import { connect } from 'react-redux';
import {bindActionCreators} from 'redux';
import { account } from '../../redux/actions/index';
import { Spin } from 'antd';
import NewAffiliate from '../../containers/newAffiliate';


const WithAuthorization = (Wrapped) => {
    class Authorization extends Component {    
        
        componentDidMount = () => {
            this.props.fetchStatus();
        }

        renderLoader = () => <Spin style={{position: "absolute", top: "40vh", left: "50vw"}} />

        render() {
            const {accountStatus} = this.props;

            if(accountStatus && accountStatus.type==='affiliate'){
                return <Wrapped {...this.props} />;
            }
            
            if(accountStatus && accountStatus.type==='no-access'){
                return <NewAffiliate />
            }

            return this.renderLoader();
        }
    }
    
const mapStateToProps = (state) =>({ 
    accountStatus: state.accountStatus 
});

const mapDispatchToProps = (dispatch) => (
    bindActionCreators({fetchStatus: () => dispatch(account.getAccountStatus)}, dispatch)
)
    
return connect(mapStateToProps, mapDispatchToProps)(Authorization);
}

export default WithAuthorization;
    