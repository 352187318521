import React from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import Layout from '../components/layout/layout'
import { Tabs, Alert, Card, List, Spin, Button, message} from 'antd';
import { affiliate } from '../redux/actions';
import {checkStatus} from '../utils/accountUtils';
import UpdateAffiliate from '../containers/updateAffiliate';
import AccountSettings from '../containers/accountSettings';


class AccountView extends React.Component {
    state = {
        edit: false,
        updateSuccess: false
    }

    componentDidMount = () => {
        this.getInfo();
    }

    getInfo = () => {
        const {accountStatus, getAffiliateInfo} = this.props;
        
        if(checkStatus(accountStatus)){
            getAffiliateInfo(accountStatus.affiID);
        }
    }

    renderUpdateForm = () => (<>
        <UpdateAffiliate 
        onGoBack={()=>{this.setState({edit:false})}} 
        onSuccess={this.onUpdateSuccess}/>
        </>
    )

    onUpdateSuccess = () => {
        this.setState({edit: false,updateSuccess: true});
        this.getInfo();
        message.success('Profile updated.');
    }

    renderInfo = () => {
        if(this.props.affiliateInfo){
            const {id, name, contact_name, website, fee} = this.props.affiliateInfo;

            const infoList =  ( <>
            <List
                itemLayout="horizontal"
                dataSource={[
                    {
                        title: "Your affiliate id",
                        value: id || 'none'
                    },
                    {
                        title: "Business name",
                        value: name || 'none'
                    },
                    {
                        title: "Your full name",
                        value: contact_name || 'none'
                    },
                    {
                        title: "Your website",
                        value: website || 'none'
                    },
                    {
                        title: "Fee",
                        value: (fee && `${fee*100}%`) || 'none'
                    },
                ]}
                renderItem={item => (
                  <List.Item>
                    <List.Item.Meta
                      title={item.title}
                      description={item.value}
                    />
                  </List.Item>
                )}
              />
              <Button style={{marginTop:10}} type="primary" onClick={()=>{this.setState({edit:true})}}>Edit</Button>
              </>)

            if(id){
                return infoList;
            }else{
                return (
                    <Spin tip="Loading data...">
                        {infoList}
                    </Spin>
                )
            }
        }
    }

    render() {
        const {edit} = this.state;
        return(
            <>
                <Card className="form-card" bordered={false}>
                    <Tabs animated={false}>
                        <Tabs.TabPane key="business" tab="Business information">
                            {edit && this.renderUpdateForm()}
                            {!edit && this.renderInfo()}
                        </Tabs.TabPane>
                        {/* <Tabs.TabPane key="account" tab="Account settings">
                            <AccountSettings />
                        </Tabs.TabPane> */}

                    </Tabs>
                </Card>
            </>
        )
    }
}

const mapStateToProps = state => ({
    user: state.user,
    accountStatus: state.accountStatus,
    affiliateInfo: state.affiliateInfo
    }
);

const mapDispatchToProps = dispatch => (
    bindActionCreators({
        getAffiliateInfo: affiliate.getAffiliateInfo
    }, dispatch)
)


const Account = connect(mapStateToProps, mapDispatchToProps)(AccountView);

export default Layout(Account);