import {get, post, put} from '../../utils/api';

export const requestStats = () => {
    return {
      type: 'REQUEST_STATS'
    }
};

export const receiveStats = (stats) => {
    return {
        type: 'RECEIVE_STATS',
        payload: stats,
        receivedAt: Date.now()
    }
};

export const failedStats  = () => (
    {
        type: 'FAILED_STATS'
    }
)

export const fetchStats = (affiID) => {
    return (dispatch) => {
        dispatch(requestStats());

        get(`/affiliates/${affiID}/summary`)
        .then(result => {dispatch(receiveStats(result.data))})
        .catch(error => {dispatch(failedStats())});        
    }
}

export const affiliateCreateRequest = () => ({
    type: 'AFFILIATE_CREATE_REQUEST'
});

export const affiliateCreateFailed = (error) => ({
    type: 'AFFILIATE_CREATE_FAILED',
    error: error
});

export const affiliateCreateSuccess = (payload) => ({
    type: 'AFFILIATE_CREATE_SUCCESS',
    payload: payload
});

export const createAffiliate = (affiliate) => (dispatch) => {
    dispatch(affiliateCreateRequest());

    post('/affiliates', affiliate)
    .then((res) => {dispatch(affiliateCreateSuccess(res.data))})
    .catch((error) => {dispatch(affiliateCreateFailed(error.response.data.error))});
}


export const affiliateUpdateRequest = () => ({
    type: 'AFFILIATE_UPDATE_REQUEST'
});

export const affiliateUpdateFailed = (error) => ({
    type: 'AFFILIATE_UPDATE_FAILED',
    error: error
});

export const affiliateUpdateSuccess = (payload) => ({
    type: 'AFFILIATE_UPDATE_SUCCESS',
    payload: payload
});

export const updateAffiliate = (affiID, newData) => (dispatch) => {
    dispatch(affiliateUpdateRequest());

    put(`/affiliates/${affiID}`, newData)
    .then((res) => {dispatch(affiliateUpdateSuccess(res.data))})
    .catch((error) => {dispatch(affiliateUpdateFailed(error.response.data.error))});
}

export const affiliateInfoRequest = () => ({
    type: 'AFFILIATE_INFO_REQUEST'
});

export const affiliateInfoFailed = (error) => ({
    type: 'AFFILIATE_INFO_FAILED',
    error: error
});

export const affiliateInfoSuccess = (payload) => ({
    type: 'AFFILIATE_INFO_SUCCESS',
    payload: payload
});

export const getAffiliateInfo = (affiID) => (dispatch) => {
    dispatch(affiliateInfoRequest());

    get(`/affiliates/${affiID}`)
    .then((res) => {dispatch(affiliateInfoSuccess(res.data))})
    .catch((error) => {dispatch(affiliateInfoFailed(error.response.data.error))});
}

export const requestVisitsChart = () => {
    return {
      type: 'VISITS_CHART_REQUEST'
    }
};

export const receiveVisitsChart = (stats) => {
    return {
        type: 'VISITS_CHART_RECEIVED',
        payload: stats,
        receivedAt: Date.now()
    }
};

export const failedVisitsChart  = () => (
    {
        type: 'VISITS_CHART_FAILED'
    }
)

export const fetchVisitsChart = (affiID) => {
    return (dispatch) => {
        dispatch(requestVisitsChart());

        get(`/affiliates/${affiID}/visits/aggregate`)
        .then(result => {dispatch(receiveVisitsChart(result.data))})
        .catch(error => {dispatch(failedVisitsChart())});        
    }
}


export const requestLeadsChart = () => {
    return {
      type: 'LEADS_CHART_REQUEST'
    }
};

export const receiveLeadsChart = (stats) => {
    return {
        type: 'LEADS_CHART_RECEIVED',
        payload: stats,
        receivedAt: Date.now()
    }
};

export const failedLeadsChart  = () => (
    {
        type: 'LEADS_CHART_FAILED'
    }
)

export const fetchLeadsChart = (affiID) => {
    return (dispatch) => {
        dispatch(requestLeadsChart());

        get(`/affiliates/${affiID}/leads/aggregate`)
        .then(result => {dispatch(receiveLeadsChart(result.data))})
        .catch(error => {dispatch(failedLeadsChart())});        
    }
}


export const requestTrialsChart = () => {
    return {
      type: 'TRIALS_CHART_REQUEST'
    }
};

export const receiveTrialsChart = (stats) => {
    return {
        type: 'TRIALS_CHART_RECEIVED',
        payload: stats,
        receivedAt: Date.now()
    }
};

export const failedTrialsChart  = () => (
    {
        type: 'TRIALS_CHART_FAILED'
    }
)

export const fetchTrialsChart = (affiID) => {
    return (dispatch) => {
        dispatch(requestTrialsChart());

        get(`/affiliates/${affiID}/trials/aggregate`)
        .then(result => {dispatch(receiveTrialsChart(result.data))})
        .catch(error => {dispatch(failedTrialsChart())});        
    }
}