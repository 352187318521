import axios from 'axios';
import { auth } from '../firebase';

const a = axios.create({
    baseURL: `${process.env.REACT_APP_API_URL}`
});
    
// const getToken = () => auth.currentUser ? auth.currentUser.getIdToken(false) : Promise.resolve("Can't get token")

export const get = (url) => (
    auth.currentUser.getIdToken(false).then((token) =>
        { 
               return a.get(url, {headers: {"Authorization" : `Bearer ${token}`}})
        }
    )
)
    
export const post = (url, body, token) => (
    auth.currentUser.getIdToken(false).then((token) =>
                    a.post(url, body, {headers: {"Authorization" : `Bearer ${token}`}})
                    )
)

export const put = (url, body, token) => (
    auth.currentUser.getIdToken(false).then((token) =>
                    a.put(url, body, {headers: {"Authorization" : `Bearer ${token}`}})
                )
)

export default {
    get: get, 
    post: post,
    put: put
};