const initialState = {
    visits: null,
    trials: null,
    leads: null,
    mrr: null
}

export const stats = (state=initialState, action) => {
    switch(action.type){
        case 'RECEIVE_STATS':
            return {...state, ...action.payload}
        case 'REQUEST_STATS':
            return initialState;
        case 'FAILED_STATS':
            return initialState;
        default:
            return state;
    }
}

export const affiliateCreateStatus = (state=null, action) => {
    switch(action.type){ 
        case 'AFFILIATE_CREATE_REQUEST':
            return {status: 'loading'};
        case 'AFFILIATE_CREATE_FAILED':
            return {status: 'error', message: action.error};
        case 'AFFILIATE_CREATE_SUCCESS':
            return {status: 'success', message: action.payload};
        default:
            return state;
    }
}

export const affiliateUpdateStatus = (state=null, action) => {
    switch(action.type){ 
        case 'AFFILIATE_UPDATE_REQUEST':
            return {status: 'loading'};
        case 'AFFILIATE_UPDATE_FAILED':
            return {status: 'error', message: action.error};
        case 'AFFILIATE_UPDATE_SUCCESS':
            return {status: 'success', message: action.payload};
        default:
            return state;
    }
}

export const visitsChartData = (state=null, action) => {
    switch(action.type){
        case 'VISITS_CHART_REQUEST':
            return null;
        case 'VISITS_CHART_FAILED':
            return null;
        case 'VISITS_CHART_RECEIVED':
            return action.payload.map(
                visit => ({x: visit.time, 
                            y: visit.count}));
        default:
            return state;
    }
}

export const trialsChartData = (state=null, action) => {
    switch(action.type){
        case 'TRIALS_CHART_REQUEST':
            return null;
        case 'TRIALS_CHART_FAILED':
            return null;
        case 'TRIALS_CHART_RECEIVED':
            return action.payload.map(
                trial => ({x: trial.time, 
                            y: trial.count}));
        default:
            return state;
    }
}

export const leadsChartData = (state=null, action) => {
    switch(action.type){
        case 'LEADS_CHART_REQUEST':
            return null;
        case 'LEADS_CHART_FAILED':
            return null;
        case 'LEADS_CHART_RECEIVED':
            return action.payload.map(
                lead => ({x: lead.time, 
                            y: lead.count}));
        default:
            return state;
    }
}

export const affiliateInfo = (state=null, action) => {
    switch(action.type){
        case 'AFFILIATE_INFO_SUCCESS':
            return action.payload;
        case 'AFFILIATE_INFO_REQUEST':
            return 'loading';
        case 'AFFILIATE_INFO_FAILED':
            return 'error';
        default:
            return state;
    }
}