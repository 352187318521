import thunkMiddleware from 'redux-thunk';
import { createLogger } from 'redux-logger';
import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import rootReducer from './reducers';
import { account } from './actions/index'


const loggerMiddleware = createLogger();

const store = createStore(
  rootReducer,
  composeWithDevTools(
  applyMiddleware(
    thunkMiddleware, // lets us dispatch() functions
    loggerMiddleware // neat middleware that logs actions
  ))
);

store.dispatch(account.verifyAuth());

export default store;