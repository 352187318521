import {get} from '../../utils/api';
import { auth } from '../../firebase';

export const requestSignIn = () => {
    return { 
        type: 'USER_SIGN_IN_REQUEST'
    }
}

export const signedIn = (user) => {
    return { 
        type: 'USER_SIGN_IN_SUCCESS',
        payload: user
    }
}

export const loginError = () => {
    return { 
        type: 'USER_SIGN_IN_FAILURE'
    }
}

export const signOut = () => {
    return { 
        type: 'USER_SIGN_OUT'
    }
}

export const signOutUser = () => ( 
    (dispatch) => {
        auth.signOut().then(
            dispatch(signOut())
        )
    }
)


export const signInUser = (email, password) => {
    return (dispatch) => {
        dispatch(requestSignIn());

        try {
            auth.signInWithEmailAndPassword(email, password)
            .then(
                (data) => {
                    dispatch(signedIn(data.user));
                }
            )
            .catch(
                (e) => {
                    dispatch(loginError()) 
                })    
        } catch (error) {
            dispatch(loginError())
        }
        
    }
}


export const requestSignUp = () => {
    return { 
        type: 'USER_SIGN_UP_REQUEST'
    }
}

export const signUpFailed = () => {
    return { 
        type: 'USER_SIGN_UP_FAILED'
    }
}

export const signUpSuccess = () => {
    return { 
        type: 'USER_SIGN_UP_SUCCESS'
    }
}

export const signUpUser = (email, password) => {
    return (dispatch) => {
        dispatch(requestSignUp());

        try {
            auth.createUserWithEmailAndPassword(email, password)
            .then(
                (data) => {
                    dispatch(signUpSuccess());
                    dispatch(signInUser(email, password));
                }
            )
            .catch(
                (e) => {
                    dispatch(signUpFailed()) 
                })    
        } catch (error) {
            dispatch(loginError())
        }
        
    }
}

export const requestStatus = () => {
    return {
        type: 'ACCOUNT_STATUS_REQUEST'
    }
}

export const receiveStatus = (status) => {
    return {
        type: 'ACCOUNT_STATUS_SUCCESS',
        payload: status
    }
}

export const failedStatus = () => {
    return {
        type: 'ACCOUNT_STATUS_FAILED'
    }
}

export const getAccountStatus = () => {
    return (dispatch) => {
        dispatch(requestStatus());

        get(`/account`)
        .then(result => {dispatch(receiveStatus(result.data))})
        .catch(error => {dispatch(failedStatus())});        
    }
}

export const verifyAuth = () => {
    return (dispatch) => {
      auth.onAuthStateChanged((user) => {
        if(user) {
            dispatch(signedIn(user));
        }else{
          dispatch(signOut());
        }
      }
      );
    }
  }
