import React, { Component } from 'react'
import { Form, Button, Alert, message, Input } from 'antd'
import { auth } from '../firebase'

export default class AccountSettings extends Component {
    state ={ 
        isLoading: false,
        newPassword: '',
        newPassword2: '',
    }

    handleSubmit = (e) => {
        const {newPassword, newPassword2} = this.state;
        this.setState({isLoading: true});
        
        if(newPassword2 === newPassword){
            if(newPassword.length>=8){
                auth.currentUser.updatePassword(newPassword)
                .then(() => {
                    message.success('Password changed.');
                    this.setState({isLoading: false, newPassword: '', newPassword2: ''});
                })
                .catch(() => {
                    message.error('Something went wrong. Please, try again.');
                    this.setState({isLoading: false});
                })
            }else{
                message.warning("Passwords must contain at least 8 characters.")
            }
        }else{
            message.warning("Passwords don't match.")
        }
    }

    handleChange = (e) => {
        e.preventDefault();
        switch(e.target.id){
            case "new-password":
                this.setState({newPassword: e.target.value});
                break;
            case "new-password2":
                this.setState({newPassword2: e.target.value});
                break;
            default: break;
        }
    }

    

    render() {
        const {isLoading, newPassword, newPassword2} = this.state
        return (
            <>
            <Form layout="vertical">
                <Form.Item  label="New password" required>
                    <Input disabled={isLoading} type="password" id="new-password" value={newPassword}  onChange={this.handleChange}/>
                </Form.Item>
                <Form.Item  label="Repeat your new password" required>
                    <Input disabled={isLoading} type="password" id="new-password2" value={newPassword2}  onChange={this.handleChange}/>
                </Form.Item>
                <Button loading={isLoading} onClick={this.handleSubmit} type="primary">Change password</Button>
            </Form>
            </>
        )
  }
}
