import React from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

import { Form, Alert, Input, Button } from 'antd';
import { account } from '../redux/actions/index';

import logo from '../img/logo.png';

class SignIn extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            username: '',
            password: '',
            redirect: false
        }
    }
    
    componentWillUpdate = (nextProps) => {
        const {user} = nextProps; 
        const {redirect} = this.state;

        if(!redirect && user && user.email) {
            this.timeout = setTimeout(() => {this.setState({redirect: true})},2000);
        }
    }

    componentWillUnmount = () => {
        clearTimeout(this.timeout);
    }

    handleLogin = (e) => {
        const {username, password} = this.state;

        this.props.dispatch(account.signInUser(username, password));
    }

    onFormKeyDown = (e) => {
        if (e.nativeEvent.keyCode === 13) {
            return this.handleLogin();
        }
    }

    handleChange = (e) => {
        e.preventDefault();
        switch(e.target.type){
            case "email": 
                this.setState({username: e.target.value}); 
                break;
            case "password":
                this.setState({password: e.target.value});
                break;
            default: break;
        }
    }

    render() {
        const {username, password, redirect} = this.state;
        const {user} = this.props;

        const isLoading = user==='loading';
        const error = user==='error';
        
        return(
                <div className="signin-container">
                    <div className="signin-content">
                        <div className="signin-logo-wrapper">
                        <img className="signin-logo" src={logo} alt="logo"/>
                        <h3 className="signin-cta">Sign in to Partner Program</h3>
                        </div>   
                        <Form onSubmit={this.handleLogin} onKeyDown={this.onFormKeyDown}>
                            <Input style={{marginBottom: "1rem", width: "100%", padding: "0 10px", borderRadius: 3}} size="large" value={username}  disabled={isLoading} type="email" placeholder="Username" onChange={this.handleChange}/>
                            <Input style={{marginBottom: "1rem", width: "100%", padding: "0 10px", borderRadius: 3}} size="large" value={password} disabled={isLoading} type="password" autoComplete="password" placeholder="Password" onChange={this.handleChange}/>
                            <Button type="primary" style={{background: "#1561d1", borderColor: "#1561d1", borderRadius: 3, color: "#fff"}} onClick={this.handleLogin} loading={isLoading}>
                            Sign in
                            </Button>
                        </Form>
                        {error && <Alert style={{marginTop: 20}} message="Can't log in. Check your credentials." type="error"/>}
                        {(user && user.email && <Alert style={{marginTop: 20}} message={`Logged in as ${user.email}. Redirecting to dashboard.`} />)}
                        {redirect && <Redirect to="/" />}
                    </div>     
            </div>
        )
    }
}


const mapStateToProps = (state) => ({
    user: state.user
})



export default connect(mapStateToProps)(SignIn);