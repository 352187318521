import React, {
    Component
  } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Spin } from 'antd';
import logo from '../../img/logo.png';


const WithAuthentication = (Wrapped) => {
    class Authentication extends Component {    
        state = { 
            loading: true
        }

        componentDidMount = () => {
            this.loaderTimeout = setTimeout(()=>{
                this.setState({loading:false});
            }, 3000);
        }

        componentWillUnmount = () => {
            clearTimeout(this.loaderTimeout);
        }
        
        renderLoginGate = () => (
            <>
                <div className="signin-container">
                    <div className="signin-content">
                        <div className="signin-logo-wrapper">
                            <img className="signin-logo" src={logo} alt="logo"/>
                            <h3 className="signin-cta">Welcome to Partner Program</h3>
                        </div>   
                        <p className="signin-gate" style={{marginBottom:10}}>Please <Link to="/signin"> log in</Link> or <Link to="/signup">create a new account</Link></p>
                        <p className="signin-gate" style={{marginTop:10}}>to get access</p>
                    </div>     
                </div>
            </>
        )

        renderLoader = () => <Spin style={{position: "absolute", top: "40vh", left: "50vw"}} />

        render() {
            if(this.props.user){
                return <Wrapped {...this.props} />;
            } 

            if(this.state.loading){
                return this.renderLoader();
            }

            return this.renderLoginGate();
        }
    }
    
const mapStateToProps = (state) =>({ user: state.user });
    
return connect(mapStateToProps)(Authentication);
}

export default WithAuthentication;
    