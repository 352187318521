import React from 'react';
import { Card as AntdCard, Skeleton } from 'antd';

const Card = ({title, content, dollar=false}) => {
const loading = content===null;

return(
<AntdCard className="data-card" style={{padding: 10}} bordered={false} title={title}>
    {loading && <Skeleton paragraph={{rows: 1, width: 50}} title={false} isLoading={loading} active />}
    {!loading && `${dollar ? '$' : ''}${content}`}
</AntdCard>)
};

export default Card;
