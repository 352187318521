const user = (state = null, action) => {
    switch(action.type) {
        case 'USER_SIGN_IN_REQUEST': 
            return 'loading';
        case 'USER_SIGN_IN_SUCCESS':
            return action.payload; 
        case 'USER_SIGN_IN_FAILURE':
            return 'error';
        case 'USER_SIGN_OUT':
            return null;
        default: 
            return state;
    }
}

const signUpStatus = (state = null, action ) => {
    switch(action.type) { 
        case 'USER_SIGN_UP_REQUEST': 
            return 'loading';
        case 'USER_SIGN_UP_SUCCESS':
            return 'success'; 
        case 'USER_SIGN_UP_FAILED':
            return 'error';
        default: 
            return state;
    }
}

const accountStatus = (state = null, action) => {
    switch(action.type) { 
        case 'ACCOUNT_STATUS_REQUEST': 
            return 'loading';
        case 'ACCOUNT_STATUS_SUCCESS':
            return action.payload; 
        case 'ACCOUNT_STATUS_FAILED':
            return 'error';
        default: 
            return state;
    }
}

export { user, signUpStatus, accountStatus };