import { combineReducers } from 'redux';
import {stats, 
        affiliateCreateStatus,
        affiliateUpdateStatus,
        visitsChartData,
        trialsChartData,
        leadsChartData,
        affiliateInfo
    } from './affiliate';
import {accountStatus, user, signUpStatus} from './account';


export default combineReducers({
    user, 
    accountStatus,
    signUpStatus,
    affiliateCreateStatus,
    affiliateUpdateStatus,
    stats, 
    visitsChartData,
    trialsChartData,
    leadsChartData,
    affiliateInfo
});