import React from 'react';
import { connect } from 'react-redux';
import {Redirect} from 'react-router-dom';
import { Card, Alert, message, Input, Button, Form } from 'antd';
import { affiliate } from '../redux/actions/index';

import Layout from '../components/layout/layout';



class NewAffiliateView extends React.Component {
    state = {
        id:'',
        name: '',
        contact_name: '',
        website: ''
    };

    componentDidMount() {
    }

    handleChange = (e) => {
        switch(e.target.id){
            case "id":
                this.setState({id:e.target.value});
                break;
            case "name":
                this.setState({name:e.target.value});
                break;
            case "contact_name":
                this.setState({contact_name:e.target.value});
                break;
            case "website":
                this.setState({website:e.target.value});
                break;
            default:
            break;
        }
    }

    validateSubmit = () => {
        const {id, name, contact_name} = this.state;

        if(!id || !name || !contact_name){
            return false;
        } 

        if(typeof id !== 'string' || typeof name !== 'string' ||  typeof contact_name !== 'string'){
            return false;
        }

        if(id.length === 0 || name.length === 0 || contact_name.length === 0){
            return false;
        }

        return true;
    };

    handleSubmit = (e) => {
        e.preventDefault();
        const {email} = this.props.user;
        const {id,name,contact_name,website} = this.state;
        
        if(this.validateSubmit()){
            this.props.dispatch(affiliate.createAffiliate({
                id: id,
                name: name,
                contact_name: contact_name,
                email: email,
                website: website
            }));
        }else{
            message.warning("Please specify all obligatory fields.");
        }
    }

    componentDidUpdate() {
        const {createStatus} = this.props;

        if(createStatus && createStatus.status && createStatus.status === 'success'){
            window.location.reload();
        }
    }

    render(){
        const {createStatus} = this.props;
        const {id, contact_name, name, website} = this.state

        const isLoading = createStatus && createStatus.status && createStatus.status === 'loading';
        const error = createStatus && createStatus.status && createStatus.status === 'error' ;
        const errorMessage = error && createStatus.message ? createStatus.message : '';  
        const success = createStatus && createStatus.status && createStatus.status === 'success' ;
        return (<>
           <Card className="form-card" title={<h1 className="form-title">Before we start, you need an affiliate profile</h1>} bordered={false}>
                <Form layout="vertical">
                    <Form.Item  label="Affiliate ID" required>
                        <Input disabled={isLoading} id="id" value={id}  onChange={this.handleChange}/>
                        This will be your unique ID in referral links. It'll look like this https://livesession.io?a=YOUR_AFFILIATE_ID
                    </Form.Item>
                    <Form.Item label="Business name" required>
                        <Input disabled={isLoading} id="name" value={name} onChange={this.handleChange}/>
                    </Form.Item>
                    <Form.Item label="Your full name" required>
                        <Input disabled={isLoading} id="contact_name" value={contact_name} onChange={this.handleChange}/>
                    </Form.Item>
                    <Form.Item label="Website">
                        <Input disabled={isLoading} id="website" value={website} onChange={this.handleChange} />
                    </Form.Item>
                    <Button loading={isLoading} onClick={this.handleSubmit} type="primary">Create profile</Button>
                    {error && <Alert style={{marginTop: 20}} message={`Can't create affiliate profile. ${errorMessage}`} type="error"/>}
                    {success && <Redirect to="/#" />}
                </Form>
            </Card>
                </>
        );
    }
}


const mapStateToProps = state => ({
    user: state.user,
    accountStatus: state.accountStatus,
    createStatus: state.affiliateCreateStatus
    }
);


const NewAffiliate = connect(mapStateToProps)(NewAffiliateView);

export default Layout(NewAffiliate);