
import React from 'react';
import { Line } from 'react-chartjs-2'; 
import { Spin } from 'antd';

const prepareData = (dataFromProps, labelFromProps) =>  ({datasets: [
        {data: dataFromProps,
        label: labelFromProps,
        borderColor: 'cornflowerblue',
        backgroundColor: 'cornflowerblue',
        fill: false,
        }
    ]}
);

const options = {
    legend: { display: false},
    scales: {
        xAxes: [{
                    type: 'time',
                    time: {
                        unit: 'day',
                        round: 'day',
                        tooltipFormat: 'MMMM Do YYYY',
                        displayFormats: {
                            day: 'DD/MM/YYYY'
                        }
                    }
        }],
        yAxes: [{
            display: true,
            ticks: {
                beginAtZero: true,
                precision: 0
            }
        }]
    }
}

class Chart extends React.Component {
    renderChart = () => {
        const {data, label} = this.props;

        return (<div className="dashboard-chart">
                    <Line data={prepareData(data, label)} options={options} />
                </div>)
    }
    
    render = () => {
        if(this.props.data!==null){
            return this.renderChart()
        }
    
        return (
            <Spin tip="Loading data...">
                {this.renderChart()}
            </Spin>
        )
    }
}


export default Chart;