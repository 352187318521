import React from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

import { Form, Alert, message, Input, Button } from 'antd';
import { account } from '../redux/actions/index';

import logo from '../img/logo.png';

class SignUp extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            username: '',
            password: '',
            password2: '',
            redirect: false
        }
    }
    
    componentWillUpdate = (nextProps) => {
        const {user} = nextProps; 

        if(user && user.email) {
            this.timeout = setTimeout(() => {this.setState({redirect: true})},2000);
        }
    }

    componentWillUnmount = () => {
        clearTimeout(this.timeout);
    }

    handleSubmit = (e) => {
        const {username, password, password2} = this.state;
        
        if(password2 === password){
            if(password.length>=8){
                this.props.dispatch(account.signUpUser(username, password));
            }else{
                message.warning("Password must contain at least 8 characters.")
            }
        }else{
            message.warning("Passwords don't match.")
        }
    }

    onFormKeyDown = (e) => {
        if (e.nativeEvent.keyCode === 13) {
            return this.handleSubmit();
        }
    }

    handleChange = (e) => {
        e.preventDefault();
        switch(e.target.id){
            case "username": 
                this.setState({username: e.target.value}); 
                break;
            case "password":
                this.setState({password: e.target.value});
                break;
            case "password2":
                this.setState({password2: e.target.value});
                break;
            default: break;
        }
    }

    render() {
        const {username, password, password2, redirect} = this.state;
        const {status, user} = this.props;

        const isLoading = status==='loading';
        const error = status==='error';
        
        return(
                <div className="signin-container">
                    <div className="signin-content">
                        <div className="signin-logo-wrapper">
                        <img className="signin-logo" src={logo} alt="logo"/>
                        <h3 className="signin-cta">Sign up to Partner Program</h3>
                        </div>   
                        <Form onSubmit={this.handleSubmit} onKeyDown={this.onFormKeyDown}>
                            <Input id="username" style={{marginBottom: "1rem", width: "100%", padding: "0 10px", borderRadius: 3}} size="large" value={username}  disabled={isLoading} type="email" placeholder="Email" onChange={this.handleChange}/>
                            <Input id="password" style={{marginBottom: "1rem", width: "100%", padding: "0 10px", borderRadius: 3}} size="large" value={password} disabled={isLoading} type="password" autoComplete="password" placeholder="Password" onChange={this.handleChange}/>
                            <Input id="password2" style={{marginBottom: "1rem", width: "100%", padding: "0 10px", borderRadius: 3}} size="large" value={password2} disabled={isLoading} type="password" autoComplete="password" placeholder="Repeat password" onChange={this.handleChange}/>                            
                            <Button type="primary" style={{background: "#1561d1", borderColor: "#1561d1", borderRadius: 3, color: "#fff"}} onClick={this.handleLogin} loading={isLoading}>
                            Sign up
                            </Button>
                        </Form>
                        {error && <Alert style={{marginTop: 20}} message="Can't create an account. Check your credentials." type="error"/>}
                        {(user && user.email && <Alert style={{marginTop: 20}} message={`Logged in as ${user.email}. Redirecting to dashboard.`} />)}
                        {redirect && <Redirect to="/" />}
                    </div>     
            </div>
        )
    }
}


const mapStateToProps = (state) => ({
    user: state.user,
    status: state.signUpStatus
})



export default connect(mapStateToProps)(SignUp);